<!--
 * @Author: lzh
 * @Date: 2022-07-05 16:37:23
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-07 12:25:29
 * @Description: file content
-->
<template>
  <div
    class="data-map-box px-data-map-box"
    :style="`background-image: url(${configs.bgImg});`"
  >
    <img
      class="image"
      :src="configs.img"
      :alt="configs.alt"
      @click="clickItem('items')"
      :class="`edit_${Xindex}_items`"
    />
    <h1
      class="title"
      @click="clickItem('title')"
      :class="`edit_${Xindex}_title`"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <h2
      class="desc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      @click="clickItem('title')"
      :class="`edit_${Xindex}_desc`"
    ></h2>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: 'self-data-map',
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: '降低人力成本 提高翻檯率 一部電話輕鬆管理餐廳',
          desc: '支持多終端設備落單幫你化繁為簡',
          img: require('../../assets/material/首頁/yuntongbu-img.png'),
          bgImg: require('../../assets/material/首頁/yuntongbu-bj.png'),
        };
      },
    },

    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: '',
    };
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e) {
      if (this.isEdit) {
        this.$emit('clickItem', e);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.data-map-box {
  font-family: PingFang SC-Semibold, PingFang SC;
  text-align: center;
  padding: 60px 0;
  .image {
    width: calc(100% - 800px);
    object-fit: cover;
    padding: 0 400px;
  }
  .title {
    font-size: 36px;
    font-weight: 600;
    color: #101010;
    padding: 40px 0 10px 0;
  }
  .desc {
    font-size: 24px;
    font-weight: 400;
    color: #101010;
  }
}

@media screen and (max-width: 1000px) {
  .px-data-map-box {
    .image {
      width: calc(100% - 112px);
      padding: 0 56px;
    }
    .title {
      font-size: 15px;
      color: #101010;
    }
    .desc {
      font-size: 14px;
      font-weight: 400;
      color: #101010;
    }
  }
}
</style>
